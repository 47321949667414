import React, { useState } from "react"
import parse from "html-react-parser"
import styled from "styled-components"
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js"
import { navigate } from "gatsby"
import { StyledCloseModalButton, StyledRegisterNowPayPalButton } from "../../styledComponents/button"
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import { StyledModal } from "../../styledComponents/modal"
import Icon from "../icon"

const PRODUCT = {
  currency: "GBP"
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`

function PayPalButtonWrapper({ price, parsedTitle, eventDate }) {
  const [{ isPending, isResolved }] = usePayPalScriptReducer()

  if (!isResolved) {
    return <div>Loading payment options…</div>
  }
  return (
    <PayPalButtons
      style={{ layout: "vertical" }}
      forceReRender={[price, parsedTitle, eventDate]}
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              description: `${parsedTitle} - ${eventDate}`,
              amount: {
                value: price,
                currency_code: PRODUCT.currency
              }
            }
          ]
        })
      }}
      onApprove={async (data, actions) => {
        const details = await actions.order.capture()
        console.log("Transaction details" + details)
        console.log("Transaction completed by " + details.payer.name.given_name)
        console.log("Transaction details:", details)
        console.log("Transaction ID:", details.id)
        console.log("Transaction amount:", details.purchase_units[0].amount.value)
        console.log("Transaction currency:", details.purchase_units[0].amount.currency_code)
        console.log("Transaction status:", details.status)
        console.log("Transaction payer email:", details.payer.email_address)
        console.log("Transaction payer name:", details.payer.name.given_name)
        console.log("Transaction payer surname:", details.payer.name.surname)

        await fetch("/.netlify/functions/paypal-webhook", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            event_type: "CHECKOUT.ORDER.APPROVED",
            resource: { id: details.id }
          })
        })

        navigate("/payment-workshop-success", {
          state: {
            name: parsedTitle,
            price,
            payer: details.payer.name.given_name,
            date: eventDate,
            email: details.payer.email_address
          }
        })
      }}
      onError={(err) => {
        console.error("PayPal Error:", err)
        alert("There was an error with your payment.")
      }}
    />
  )
}

const stripHtml = (htmlString) => {
  const tmp = document.createElement("div")
  tmp.innerHTML = htmlString
  return tmp.textContent || tmp.innerText || ""
}


function FancyModalButton({ eventTitle, price, readableDate }) {
  const parsedTitle = parse(eventTitle)         // rendered in UI
  const cleanTitle = stripHtml(eventTitle)      // sent to PayPal


  const [isOpen, setIsOpen] = useState(false)
  const [opacity, setOpacity] = useState(0)
  const [paypalReady, setPaypalReady] = useState(false)

  function toggleModal() {
    setOpacity(0)
    setIsOpen(!isOpen)
    if (!isOpen) setPaypalReady(false)
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1)
      setPaypalReady(true)
    }, 100)
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0)
      setTimeout(resolve, 300)
    })
  }

  return (
    <>
      <StyledRegisterNowPayPalButton onClick={toggleModal}>Register with <Icon name="paypal" /></StyledRegisterNowPayPalButton>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        className="deposit-modal"
      >
        <StyledCloseModalButton onClick={toggleModal}>
          <Icon name="cross" />
        </StyledCloseModalButton>
        <h2>Register with PayPal</h2>
        <p>To register for the <strong>{parsedTitle}</strong> workshop please use the PayPal buttons below.</p>
        <PayPalScriptProvider options={{ "client-id": process.env.GATSBY_PAYPAL_CLIENT_ID, currency: PRODUCT.currency }}>
          {paypalReady && (
            <PayPalButtonWrapper price={price} parsedTitle={cleanTitle}  eventDate={readableDate} />
          )}
        </PayPalScriptProvider>
      </StyledModal>
    </>
  )
}

const ProductCheckout = ({ eventData, price, isModalButton, eventDate }) => {
  // Replace underscore with space
  const formattedStr = (eventDate || '').replace('_', ' ')

  // Create a Date object
  const dateObj = new Date(formattedStr);

  // Initialize readableDate
  let readableDate = 'Invalid date format';

  // Check if the date is valid
  if (!isNaN(dateObj)) {
    // Format the date
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

    const formattedDate = dateObj.toLocaleDateString('en-GB', dateOptions);
    const formattedTime = dateObj.toLocaleTimeString('en-GB', timeOptions);

    readableDate = `${formattedDate}`;
  }
  const parsedTitle = parse(eventData.eventTitle)
  const cleanTitle = stripHtml(eventData.eventTitle)      // sent to PayPal
  return (
    <>
      {isModalButton ?
        <ModalProvider backgroundComponent={FadingBackground}>
          <FancyModalButton
            eventTitle={eventData.eventTitle}
            price={price}
            readableDate={readableDate}
          />
        </ModalProvider>
        :
        <PayPalScriptProvider options={{ "client-id": process.env.GATSBY_PAYPAL_CLIENT_ID, currency: PRODUCT.currency }}>
          <PayPalButtonWrapper price={price} parsedTitle={cleanTitle} eventDate={readableDate} />
        </PayPalScriptProvider>
      }
    </>
  )
}

export default ProductCheckout
